html, body {
  overflow: hidden;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
  border-radius: 23111998px;
}

*::-webkit-scrollbar-thumb {
  background-color: #ccc;
  outline: 1px solid #bbb;
  border-radius: 23111998px;
}

.navbar.is-success {
  background-color: #1D8753 !important;
  color: #fff;
}

.user-nav {
  padding-top: 20px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 20px;
  z-index: 29;
  display: flex;
  align-items: stretch;
}

.navbar-item, .navbar-link {
  padding: 0 !important;
}

.user-nav .navbar-start {
  justify-content: flex-start;
  margin-right: auto;
}

.user-nav .navbar-end {
  justify-content: flex-end;
  margin-left: auto;
}

.user-nav .navbar-start,
.user-nav .navbar-end {
  align-items: stretch;
  display: flex;
}

.user-nav .navbar-item:hover {
  color: currentColor;
}

.menu {
  cursor: pointer;
}

.menu:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in;
}

.submenu-link,
.submenu-link:link, 
.submenu-link:visited, 
.submenu-link:active {
  display: block;
  position: relative;
  font-size: 0.5rem;
  letter-spacing: 1px;
  cursor: pointer;
  text-decoration: none;
  outline: none;
}

.dropdown {
  position: relative;
}

.dropdown .nav-link {
  padding-right: 15px;
  height: 17px;
  line-height: 17px;
}

.dropdown .nav-link::after {
  content: "";
  position:absolute;
  top: 6px;
  right: 0;
  border: 5px solid transparent;
  border-top-color: #fff;
}

.submenu {
  position: absolute;
  top: 25%;
  left: 65%;
  z-index: 100;
  width: 165px;
  margin-left: -100px;
  background: #fff;
  border-radius: 3px;
  line-height: 1.46667;
  margin-top: -5px;
  box-shadow: 0 0 8px rgba(0,0,0,.3);
  opacity:0;
  -webkit-transform: translate(0, 0) scale(.85);
  transform: translate(0, 0)scale(.85);
  transition: transform 0.1s ease-out, opacity 0.1s ease-out;
  pointer-events: none;
}

/*.submenu::after, 
.submenu::before {
  content: ""; 
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -10px;
  border: 10px solid transparent;
  height: 0;
}

.submenu::after {
  border-bottom-color: #fff;
}

.submenu::before {
  margin-left: -13px;
  border: 13px solid transparent;
  border-bottom-color: rgba(0,0,0,.1);
  -webkit-filter:blur(1px);
  filter:blur(1px);
}*/

.submenu-items {
  list-style: none;
  padding: 10px 0;
}

.submenu-item {
  display: block;
  text-align: left;
}

.submenu-link,
.submenu-link:link, 
.submenu-link:visited, 
.submenu-link:active {
  color: #3498db;
  padding: 10px 10px;
}

.submenu-link:hover {
  text-decoration: underline;
}

.submenu-seperator {
  height: 0;
  margin: 12px 10px;
  border-top: 1px solid #eee;
}

.show-submenu .submenu {
  opacity: 1;
  -webkit-transform: translate(0, 25px) scale(1);
  transform: translate(0, 25px) scale(1);
  pointer-events: auto;
}

.hero-body {
  height:100%; 
  position: relative;
  background: url('./bot.png');
}

.messages-window {
  height: 100%;
  width: 92.5%;
  overflow: hidden;
  box-sizing: border-box;
  position: absolute !important;
  top: 0;  
}

@media screen and (min-width: 992px) {
  .messages-window {
     width: 97.5%;
  }

  .submenu {
    top: 55%;
    margin-left: -137px;
    width: 175px;
  }

  .submenu-link,
  .submenu-link:link, 
  .submenu-link:visited, 
  .submenu-link:active {
    font-size: 0.6rem;
  }

  .submenu-link,
  .submenu-link:link, 
  .submenu-link:visited, 
  .submenu-link:active {
    color: #3498db;
    padding: 10px 20px;
  }

}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .messages-window {
     width: 97.5%;
  }
}

.chats
{ 
  position: relative;
  max-height: 100%;
  overflow: auto;
  box-sizing: border-box;
  overflow:auto;
  -webkit-overflow-scrolling: touch;
}

.hero-foot {
  padding: 10px 12px;
}

.hero-foot .input {
  border-radius: 23111998px !important;
  background-color: #f9f9f9;
}

.hero-foot .button {
  padding: 0 5px;
  border: none;
}